import {
    Button,
    Heading1,
    Heading2,
    Stack,
    useNavBar,
} from '@phx/design-system';
import { useIsMobile } from '@phx/design-system/hooks';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { RxForUser } from '../../../components/cabinet/rx-for-user/RxForUser';
import { Prescriber } from '../../../components/prescriber/Prescriber';
import { ConditionalComponent } from '../../../components/utility/ConditionalComponent';
import { getFragment } from '../../../graphql';
import {
    type DigitallyReceivedPrescriberOrderInfoFragment,
    PrescriberOrderStatus,
    type PriceQuoteInfoFragment,
} from '../../../graphql/generated/graphql';
import { getAbsoluteRoute } from '../../../util';

import { LastOrderActivity } from './LastOrderActivity';
import { PrescriberOrderDetails } from './PrescriberOrderDetails';

type OrderDetailsProps = {
    priceQuote?: PriceQuoteInfoFragment;
    prescriberOrder: DigitallyReceivedPrescriberOrderInfoFragment;
};

export const OrderDetails = ({
    priceQuote,
    prescriberOrder,
}: OrderDetailsProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isMobile = useIsMobile();

    const { setBackOverrideState } = useNavBar();

    useEffect(() => {
        if (prescriberOrder.latestSelectedPriceQuote) {
            setBackOverrideState({
                overrideFn: () => {
                    navigate(getAbsoluteRoute('cabinet.history'));
                },
            });
        }

        return () => setBackOverrideState({ overrideFn: null });
    }, [prescriberOrder.latestSelectedPriceQuote]);

    const prescriptionId = prescriberOrder.drxId ?? prescriberOrder.id;
    const rxPatient = getFragment(prescriberOrder?.patient);

    return (
        <Stack gap="lg" flex="1">
            <Stack gap="xl" flex={isMobile ? '1' : '0'}>
                <Stack gap="lg">
                    <Stack gap="xs">
                        <RxForUser patient={rxPatient} />
                        <Heading1>
                            {prescriberOrder.productDescription}
                        </Heading1>
                    </Stack>
                    <PrescriberOrderDetails
                        showQuantity
                        prescriberOrder={prescriberOrder}
                        priceQuote={priceQuote}
                        showPrescriber={false}
                    />
                    <LastOrderActivity
                        prescriberOrder={prescriberOrder}
                        priceQuote={priceQuote}
                    />
                </Stack>
                <Heading2>
                    {t('orderConfirmation.prescriber.subHeader')}
                </Heading2>
                <Prescriber
                    prescriber={getFragment(prescriberOrder.prescriber)}
                />
            </Stack>
            <ConditionalComponent
                condition={!prescriberOrder.applicablePriorAuthorization}
            >
                {prescriberOrder.status === PrescriberOrderStatus.New ? (
                    <Button
                        onClick={() =>
                            navigate(
                                getAbsoluteRoute('cabinet.rxId.root', {
                                    prescriptionId,
                                })
                            )
                        }
                    >
                        {t('orderDetails.fillPrescription')}
                    </Button>
                ) : null}
            </ConditionalComponent>
        </Stack>
    );
};
