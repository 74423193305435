import {
    Body1,
    Box,
    Button,
    Heading1,
    Heading2,
    HorizontalDivider,
    IconFileOpen,
    Stack,
    TextButton,
} from '@phx/design-system';
import { PharmacyInfo } from '@phx/myphx-lib';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getFragment } from '../../graphql';
import type {
    DigitallyReceivedPrescriberOrderInfoFragment,
    MailOrderProviderInfoFragment,
    PharmacyProviderInfoFragment,
    PriorAuthorizationInfoFragment,
} from '../../graphql/generated/graphql';
import { PriorAuthorizationStatus as PriorAuthorizationStatusType } from '../../graphql/generated/graphql';
import { usePatientContext } from '../../hooks/use-patient-context';
import { getAbsoluteRoute } from '../../util';
import { ContactUsWidget } from '../common/ContactUsWidget';
import { ShareWithPharmacy } from '../order-confirmation/components/ShareWithPharmacy';
import { PatientProductDetails } from '../preview-details/components/PatientProductDetails';

import {
    getStatusTranslation,
    PriorAuthStatusDescription,
} from './components/PriorAuthStatusDescriptions';
import { PriorAuthTimeline } from './components/PriorAuthTimeline';
type PriorAuthorizationStatusProps = {
    prescriberOrder: DigitallyReceivedPrescriberOrderInfoFragment;
    priorAuthInfo: PriorAuthorizationInfoFragment;
    location?: PharmacyProviderInfoFragment | MailOrderProviderInfoFragment;
};

export const PriorAuthorizationStatus = ({
    prescriberOrder,
    priorAuthInfo,
    location,
}: PriorAuthorizationStatusProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { preferences } = usePatientContext();
    const { favoritePharmacies } = preferences;
    const { checkIsFavorite, toggleFavorite } = favoritePharmacies;

    const patient = getFragment(prescriberOrder.patient);
    const product = prescriberOrder.productDescription;
    const quantity = prescriberOrder.quantity;

    const offer =
        priorAuthInfo.status === PriorAuthorizationStatusType.Approved ||
        priorAuthInfo.status === PriorAuthorizationStatusType.AppealApproved
            ? getFragment(prescriberOrder?.latestSelectedOffer)
            : undefined;

    const hasDecisionLetter = useMemo(() => {
        const documentUri = priorAuthInfo.documents[0].documentUri;
        return documentUri
            ? () => window.open(documentUri, '_blank')
            : undefined;
    }, [priorAuthInfo.documents]);

    const determiner = hasDecisionLetter
        ? t('priorAuthorization.determiner.this')
        : t('priorAuthorization.determiner.a');

    return (
        <Stack>
            <PatientProductDetails
                patientName={patient.fullName}
                productDescription={product}
                quantity={quantity}
                prescriberOrder={prescriberOrder}
            />
            <HorizontalDivider />

            <Stack gap="xl">
                <Stack gap="sm">
                    <Heading1>
                        {t('priorAuthorization.statusPage.currentStatus')}
                        {priorAuthInfo.status ===
                        PriorAuthorizationStatusType.Cancelled
                            ? t('priorAuthorization.status.cancelled')
                            : getStatusTranslation(priorAuthInfo.status)}
                    </Heading1>
                    <PriorAuthStatusDescription
                        openDecisionLetter={hasDecisionLetter}
                        determiner={determiner}
                        status={priorAuthInfo.status}
                    />
                </Stack>
                {offer ? (
                    <Stack gap="sm">
                        <ShareWithPharmacy
                            offer={offer}
                            prescriberOrder={prescriberOrder}
                        />
                        <Body1>
                            {t('priorAuthorization.statusPage.decisionLetter', {
                                determiner,
                            })}
                        </Body1>
                        {hasDecisionLetter ? (
                            <Box>
                                <TextButton
                                    onClick={hasDecisionLetter}
                                    LeftIcon={IconFileOpen}
                                >
                                    {t(
                                        'priorAuthorization.statusPage.viewDecisionLetter'
                                    )}
                                </TextButton>
                            </Box>
                        ) : null}
                    </Stack>
                ) : undefined}
                <PriorAuthTimeline priorAuthInfo={priorAuthInfo} />

                {location ? (
                    <Stack gap="sm">
                        <Stack gap="xxs">
                            <Heading2>{t('previewDetails.heading')}</Heading2>
                            <Body1>
                                {t(
                                    'priorAuthorization.statusPage.sentPrescription'
                                )}
                            </Body1>
                        </Stack>
                        <PharmacyInfo
                            name={location.name}
                            phone={location.phoneNumber}
                            favorite={checkIsFavorite(location.id)}
                            toggleFavorite={() => toggleFavorite(location.id)}
                            shippingTime={
                                status.includes('Approved') ? '2-4' : undefined
                            }
                        />
                    </Stack>
                ) : null}
            </Stack>

            <HorizontalDivider />
            <ContactUsWidget />
            <Button
                onClick={() => {
                    navigate(getAbsoluteRoute('home'));
                }}
            >
                {t('common.button.returnToHome')}
            </Button>
        </Stack>
    );
};
