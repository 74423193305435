var __create = Object.create;
var __getProtoOf = Object.getPrototypeOf;
var __defProp = Object.defineProperty;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __toESM = (mod, isNodeMode, target) => {
  target = mod != null ? __create(__getProtoOf(mod)) : {};
  const to = isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target;
  for (let key of __getOwnPropNames(mod))
    if (!__hasOwnProp.call(to, key))
      __defProp(to, key, {
        get: () => mod[key],
        enumerable: true
      });
  return to;
};
var __moduleCache = /* @__PURE__ */ new WeakMap;
var __toCommonJS = (from) => {
  var entry = __moduleCache.get(from), desc;
  if (entry)
    return entry;
  entry = __defProp({}, "__esModule", { value: true });
  if (from && typeof from === "object" || typeof from === "function")
    __getOwnPropNames(from).map((key) => !__hasOwnProp.call(entry, key) && __defProp(entry, key, {
      get: () => from[key],
      enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable
    }));
  __moduleCache.set(from, entry);
  return entry;
};
var __commonJS = (cb, mod) => () => (mod || cb((mod = { exports: {} }).exports, mod), mod.exports);
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, {
      get: all[name],
      enumerable: true,
      configurable: true,
      set: (newValue) => all[name] = () => newValue
    });
};
var __esm = (fn, res) => () => (fn && (res = fn(fn = 0)), res);

// src/client/components/AuthProvider.tsx
import { Body1 as Body12, Button as Button2, Modal as Modal2, Stack as Stack2 } from "@phx/design-system";
import { useIdle } from "@phx/design-system/hooks";
import {
  createContext,
  useCallback as useCallback2,
  useEffect as useEffect2
} from "react";
import { useTranslation as useTranslation2 } from "react-i18next";

// ../../../node_modules/jose/dist/browser/runtime/webcrypto.js
var webcrypto_default = crypto;
var isCryptoKey = (key) => key instanceof CryptoKey;

// ../../../node_modules/jose/dist/browser/lib/buffer_utils.js
function concat(...buffers) {
  const size = buffers.reduce((acc, { length }) => acc + length, 0);
  const buf = new Uint8Array(size);
  let i = 0;
  buffers.forEach((buffer) => {
    buf.set(buffer, i);
    i += buffer.length;
  });
  return buf;
}
var encoder = new TextEncoder;
var decoder = new TextDecoder;
var MAX_INT32 = 2 ** 32;

// ../../../node_modules/jose/dist/browser/runtime/base64url.js
var decodeBase64 = (encoded) => {
  const binary = atob(encoded);
  const bytes = new Uint8Array(binary.length);
  for (let i = 0;i < binary.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }
  return bytes;
};
var decode = (input) => {
  let encoded = input;
  if (encoded instanceof Uint8Array) {
    encoded = decoder.decode(encoded);
  }
  encoded = encoded.replace(/-/g, "+").replace(/_/g, "/").replace(/\s/g, "");
  try {
    return decodeBase64(encoded);
  } catch (_a) {
    throw new TypeError("The input to be decoded is not correctly encoded.");
  }
};

// ../../../node_modules/jose/dist/browser/util/errors.js
class JOSEError extends Error {
  static get code() {
    return "ERR_JOSE_GENERIC";
  }
  constructor(message) {
    var _a;
    super(message);
    this.code = "ERR_JOSE_GENERIC";
    this.name = this.constructor.name;
    (_a = Error.captureStackTrace) === null || _a === undefined || _a.call(Error, this, this.constructor);
  }
}

class JWTClaimValidationFailed extends JOSEError {
  static get code() {
    return "ERR_JWT_CLAIM_VALIDATION_FAILED";
  }
  constructor(message, claim = "unspecified", reason = "unspecified") {
    super(message);
    this.code = "ERR_JWT_CLAIM_VALIDATION_FAILED";
    this.claim = claim;
    this.reason = reason;
  }
}

class JWTExpired extends JOSEError {
  static get code() {
    return "ERR_JWT_EXPIRED";
  }
  constructor(message, claim = "unspecified", reason = "unspecified") {
    super(message);
    this.code = "ERR_JWT_EXPIRED";
    this.claim = claim;
    this.reason = reason;
  }
}

class JOSEAlgNotAllowed extends JOSEError {
  constructor() {
    super(...arguments);
    this.code = "ERR_JOSE_ALG_NOT_ALLOWED";
  }
  static get code() {
    return "ERR_JOSE_ALG_NOT_ALLOWED";
  }
}

class JOSENotSupported extends JOSEError {
  constructor() {
    super(...arguments);
    this.code = "ERR_JOSE_NOT_SUPPORTED";
  }
  static get code() {
    return "ERR_JOSE_NOT_SUPPORTED";
  }
}
class JWSInvalid extends JOSEError {
  constructor() {
    super(...arguments);
    this.code = "ERR_JWS_INVALID";
  }
  static get code() {
    return "ERR_JWS_INVALID";
  }
}

class JWTInvalid extends JOSEError {
  constructor() {
    super(...arguments);
    this.code = "ERR_JWT_INVALID";
  }
  static get code() {
    return "ERR_JWT_INVALID";
  }
}
class JWSSignatureVerificationFailed extends JOSEError {
  constructor() {
    super(...arguments);
    this.code = "ERR_JWS_SIGNATURE_VERIFICATION_FAILED";
    this.message = "signature verification failed";
  }
  static get code() {
    return "ERR_JWS_SIGNATURE_VERIFICATION_FAILED";
  }
}

// ../../../node_modules/jose/dist/browser/lib/crypto_key.js
function unusable(name, prop = "algorithm.name") {
  return new TypeError(`CryptoKey does not support this operation, its ${prop} must be ${name}`);
}
function isAlgorithm(algorithm, name) {
  return algorithm.name === name;
}
function getHashLength(hash) {
  return parseInt(hash.name.slice(4), 10);
}
function getNamedCurve(alg) {
  switch (alg) {
    case "ES256":
      return "P-256";
    case "ES384":
      return "P-384";
    case "ES512":
      return "P-521";
    default:
      throw new Error("unreachable");
  }
}
function checkUsage(key, usages) {
  if (usages.length && !usages.some((expected) => key.usages.includes(expected))) {
    let msg = "CryptoKey does not support this operation, its usages must include ";
    if (usages.length > 2) {
      const last = usages.pop();
      msg += `one of ${usages.join(", ")}, or ${last}.`;
    } else if (usages.length === 2) {
      msg += `one of ${usages[0]} or ${usages[1]}.`;
    } else {
      msg += `${usages[0]}.`;
    }
    throw new TypeError(msg);
  }
}
function checkSigCryptoKey(key, alg, ...usages) {
  switch (alg) {
    case "HS256":
    case "HS384":
    case "HS512": {
      if (!isAlgorithm(key.algorithm, "HMAC"))
        throw unusable("HMAC");
      const expected = parseInt(alg.slice(2), 10);
      const actual = getHashLength(key.algorithm.hash);
      if (actual !== expected)
        throw unusable(`SHA-${expected}`, "algorithm.hash");
      break;
    }
    case "RS256":
    case "RS384":
    case "RS512": {
      if (!isAlgorithm(key.algorithm, "RSASSA-PKCS1-v1_5"))
        throw unusable("RSASSA-PKCS1-v1_5");
      const expected = parseInt(alg.slice(2), 10);
      const actual = getHashLength(key.algorithm.hash);
      if (actual !== expected)
        throw unusable(`SHA-${expected}`, "algorithm.hash");
      break;
    }
    case "PS256":
    case "PS384":
    case "PS512": {
      if (!isAlgorithm(key.algorithm, "RSA-PSS"))
        throw unusable("RSA-PSS");
      const expected = parseInt(alg.slice(2), 10);
      const actual = getHashLength(key.algorithm.hash);
      if (actual !== expected)
        throw unusable(`SHA-${expected}`, "algorithm.hash");
      break;
    }
    case "EdDSA": {
      if (key.algorithm.name !== "Ed25519" && key.algorithm.name !== "Ed448") {
        throw unusable("Ed25519 or Ed448");
      }
      break;
    }
    case "ES256":
    case "ES384":
    case "ES512": {
      if (!isAlgorithm(key.algorithm, "ECDSA"))
        throw unusable("ECDSA");
      const expected = getNamedCurve(alg);
      const actual = key.algorithm.namedCurve;
      if (actual !== expected)
        throw unusable(expected, "algorithm.namedCurve");
      break;
    }
    default:
      throw new TypeError("CryptoKey does not support this operation");
  }
  checkUsage(key, usages);
}

// ../../../node_modules/jose/dist/browser/lib/invalid_key_input.js
function message(msg, actual, ...types) {
  if (types.length > 2) {
    const last = types.pop();
    msg += `one of type ${types.join(", ")}, or ${last}.`;
  } else if (types.length === 2) {
    msg += `one of type ${types[0]} or ${types[1]}.`;
  } else {
    msg += `of type ${types[0]}.`;
  }
  if (actual == null) {
    msg += ` Received ${actual}`;
  } else if (typeof actual === "function" && actual.name) {
    msg += ` Received function ${actual.name}`;
  } else if (typeof actual === "object" && actual != null) {
    if (actual.constructor && actual.constructor.name) {
      msg += ` Received an instance of ${actual.constructor.name}`;
    }
  }
  return msg;
}
function withAlg(alg, actual, ...types) {
  return message(`Key for the ${alg} algorithm must be `, actual, ...types);
}
var invalid_key_input_default = (actual, ...types) => {
  return message("Key must be ", actual, ...types);
};

// ../../../node_modules/jose/dist/browser/runtime/is_key_like.js
var is_key_like_default = (key) => {
  return isCryptoKey(key);
};
var types = ["CryptoKey"];

// ../../../node_modules/jose/dist/browser/lib/is_disjoint.js
var isDisjoint = (...headers) => {
  const sources = headers.filter(Boolean);
  if (sources.length === 0 || sources.length === 1) {
    return true;
  }
  let acc;
  for (const header of sources) {
    const parameters = Object.keys(header);
    if (!acc || acc.size === 0) {
      acc = new Set(parameters);
      continue;
    }
    for (const parameter of parameters) {
      if (acc.has(parameter)) {
        return false;
      }
      acc.add(parameter);
    }
  }
  return true;
};
var is_disjoint_default = isDisjoint;

// ../../../node_modules/jose/dist/browser/lib/is_object.js
function isObjectLike(value) {
  return typeof value === "object" && value !== null;
}
function isObject(input) {
  if (!isObjectLike(input) || Object.prototype.toString.call(input) !== "[object Object]") {
    return false;
  }
  if (Object.getPrototypeOf(input) === null) {
    return true;
  }
  let proto = input;
  while (Object.getPrototypeOf(proto) !== null) {
    proto = Object.getPrototypeOf(proto);
  }
  return Object.getPrototypeOf(input) === proto;
}

// ../../../node_modules/jose/dist/browser/runtime/check_key_length.js
var check_key_length_default = (alg, key) => {
  if (alg.startsWith("RS") || alg.startsWith("PS")) {
    const { modulusLength } = key.algorithm;
    if (typeof modulusLength !== "number" || modulusLength < 2048) {
      throw new TypeError(`${alg} requires key modulusLength to be 2048 bits or larger`);
    }
  }
};

// ../../../node_modules/jose/dist/browser/runtime/jwk_to_key.js
function subtleMapping(jwk) {
  let algorithm;
  let keyUsages;
  switch (jwk.kty) {
    case "oct": {
      switch (jwk.alg) {
        case "HS256":
        case "HS384":
        case "HS512":
          algorithm = { name: "HMAC", hash: `SHA-${jwk.alg.slice(-3)}` };
          keyUsages = ["sign", "verify"];
          break;
        case "A128CBC-HS256":
        case "A192CBC-HS384":
        case "A256CBC-HS512":
          throw new JOSENotSupported(`${jwk.alg} keys cannot be imported as CryptoKey instances`);
        case "A128GCM":
        case "A192GCM":
        case "A256GCM":
        case "A128GCMKW":
        case "A192GCMKW":
        case "A256GCMKW":
          algorithm = { name: "AES-GCM" };
          keyUsages = ["encrypt", "decrypt"];
          break;
        case "A128KW":
        case "A192KW":
        case "A256KW":
          algorithm = { name: "AES-KW" };
          keyUsages = ["wrapKey", "unwrapKey"];
          break;
        case "PBES2-HS256+A128KW":
        case "PBES2-HS384+A192KW":
        case "PBES2-HS512+A256KW":
          algorithm = { name: "PBKDF2" };
          keyUsages = ["deriveBits"];
          break;
        default:
          throw new JOSENotSupported('Invalid or unsupported JWK "alg" (Algorithm) Parameter value');
      }
      break;
    }
    case "RSA": {
      switch (jwk.alg) {
        case "PS256":
        case "PS384":
        case "PS512":
          algorithm = { name: "RSA-PSS", hash: `SHA-${jwk.alg.slice(-3)}` };
          keyUsages = jwk.d ? ["sign"] : ["verify"];
          break;
        case "RS256":
        case "RS384":
        case "RS512":
          algorithm = { name: "RSASSA-PKCS1-v1_5", hash: `SHA-${jwk.alg.slice(-3)}` };
          keyUsages = jwk.d ? ["sign"] : ["verify"];
          break;
        case "RSA-OAEP":
        case "RSA-OAEP-256":
        case "RSA-OAEP-384":
        case "RSA-OAEP-512":
          algorithm = {
            name: "RSA-OAEP",
            hash: `SHA-${parseInt(jwk.alg.slice(-3), 10) || 1}`
          };
          keyUsages = jwk.d ? ["decrypt", "unwrapKey"] : ["encrypt", "wrapKey"];
          break;
        default:
          throw new JOSENotSupported('Invalid or unsupported JWK "alg" (Algorithm) Parameter value');
      }
      break;
    }
    case "EC": {
      switch (jwk.alg) {
        case "ES256":
          algorithm = { name: "ECDSA", namedCurve: "P-256" };
          keyUsages = jwk.d ? ["sign"] : ["verify"];
          break;
        case "ES384":
          algorithm = { name: "ECDSA", namedCurve: "P-384" };
          keyUsages = jwk.d ? ["sign"] : ["verify"];
          break;
        case "ES512":
          algorithm = { name: "ECDSA", namedCurve: "P-521" };
          keyUsages = jwk.d ? ["sign"] : ["verify"];
          break;
        case "ECDH-ES":
        case "ECDH-ES+A128KW":
        case "ECDH-ES+A192KW":
        case "ECDH-ES+A256KW":
          algorithm = { name: "ECDH", namedCurve: jwk.crv };
          keyUsages = jwk.d ? ["deriveBits"] : [];
          break;
        default:
          throw new JOSENotSupported('Invalid or unsupported JWK "alg" (Algorithm) Parameter value');
      }
      break;
    }
    case "OKP": {
      switch (jwk.alg) {
        case "EdDSA":
          algorithm = { name: jwk.crv };
          keyUsages = jwk.d ? ["sign"] : ["verify"];
          break;
        case "ECDH-ES":
        case "ECDH-ES+A128KW":
        case "ECDH-ES+A192KW":
        case "ECDH-ES+A256KW":
          algorithm = { name: jwk.crv };
          keyUsages = jwk.d ? ["deriveBits"] : [];
          break;
        default:
          throw new JOSENotSupported('Invalid or unsupported JWK "alg" (Algorithm) Parameter value');
      }
      break;
    }
    default:
      throw new JOSENotSupported('Invalid or unsupported JWK "kty" (Key Type) Parameter value');
  }
  return { algorithm, keyUsages };
}
var parse = async (jwk) => {
  var _a, _b;
  if (!jwk.alg) {
    throw new TypeError('"alg" argument is required when "jwk.alg" is not present');
  }
  const { algorithm, keyUsages } = subtleMapping(jwk);
  const rest = [
    algorithm,
    (_a = jwk.ext) !== null && _a !== undefined ? _a : false,
    (_b = jwk.key_ops) !== null && _b !== undefined ? _b : keyUsages
  ];
  if (algorithm.name === "PBKDF2") {
    return webcrypto_default.subtle.importKey("raw", decode(jwk.k), ...rest);
  }
  const keyData = { ...jwk };
  delete keyData.alg;
  delete keyData.use;
  return webcrypto_default.subtle.importKey("jwk", keyData, ...rest);
};
var jwk_to_key_default = parse;

// ../../../node_modules/jose/dist/browser/key/import.js
async function importJWK(jwk, alg, octAsKeyObject) {
  var _a;
  if (!isObject(jwk)) {
    throw new TypeError("JWK must be an object");
  }
  alg || (alg = jwk.alg);
  switch (jwk.kty) {
    case "oct":
      if (typeof jwk.k !== "string" || !jwk.k) {
        throw new TypeError('missing "k" (Key Value) Parameter value');
      }
      octAsKeyObject !== null && octAsKeyObject !== undefined || (octAsKeyObject = jwk.ext !== true);
      if (octAsKeyObject) {
        return jwk_to_key_default({ ...jwk, alg, ext: (_a = jwk.ext) !== null && _a !== undefined ? _a : false });
      }
      return decode(jwk.k);
    case "RSA":
      if (jwk.oth !== undefined) {
        throw new JOSENotSupported('RSA JWK "oth" (Other Primes Info) Parameter value is not supported');
      }
    case "EC":
    case "OKP":
      return jwk_to_key_default({ ...jwk, alg });
    default:
      throw new JOSENotSupported('Unsupported "kty" (Key Type) Parameter value');
  }
}

// ../../../node_modules/jose/dist/browser/lib/check_key_type.js
var symmetricTypeCheck = (alg, key) => {
  if (key instanceof Uint8Array)
    return;
  if (!is_key_like_default(key)) {
    throw new TypeError(withAlg(alg, key, ...types, "Uint8Array"));
  }
  if (key.type !== "secret") {
    throw new TypeError(`${types.join(" or ")} instances for symmetric algorithms must be of type "secret"`);
  }
};
var asymmetricTypeCheck = (alg, key, usage) => {
  if (!is_key_like_default(key)) {
    throw new TypeError(withAlg(alg, key, ...types));
  }
  if (key.type === "secret") {
    throw new TypeError(`${types.join(" or ")} instances for asymmetric algorithms must not be of type "secret"`);
  }
  if (usage === "sign" && key.type === "public") {
    throw new TypeError(`${types.join(" or ")} instances for asymmetric algorithm signing must be of type "private"`);
  }
  if (usage === "decrypt" && key.type === "public") {
    throw new TypeError(`${types.join(" or ")} instances for asymmetric algorithm decryption must be of type "private"`);
  }
  if (key.algorithm && usage === "verify" && key.type === "private") {
    throw new TypeError(`${types.join(" or ")} instances for asymmetric algorithm verifying must be of type "public"`);
  }
  if (key.algorithm && usage === "encrypt" && key.type === "private") {
    throw new TypeError(`${types.join(" or ")} instances for asymmetric algorithm encryption must be of type "public"`);
  }
};
var checkKeyType = (alg, key, usage) => {
  const symmetric = alg.startsWith("HS") || alg === "dir" || alg.startsWith("PBES2") || /^A\d{3}(?:GCM)?KW$/.test(alg);
  if (symmetric) {
    symmetricTypeCheck(alg, key);
  } else {
    asymmetricTypeCheck(alg, key, usage);
  }
};
var check_key_type_default = checkKeyType;

// ../../../node_modules/jose/dist/browser/lib/validate_crit.js
function validateCrit(Err, recognizedDefault, recognizedOption, protectedHeader, joseHeader) {
  if (joseHeader.crit !== undefined && protectedHeader.crit === undefined) {
    throw new Err('"crit" (Critical) Header Parameter MUST be integrity protected');
  }
  if (!protectedHeader || protectedHeader.crit === undefined) {
    return new Set;
  }
  if (!Array.isArray(protectedHeader.crit) || protectedHeader.crit.length === 0 || protectedHeader.crit.some((input) => typeof input !== "string" || input.length === 0)) {
    throw new Err('"crit" (Critical) Header Parameter MUST be an array of non-empty strings when present');
  }
  let recognized;
  if (recognizedOption !== undefined) {
    recognized = new Map([...Object.entries(recognizedOption), ...recognizedDefault.entries()]);
  } else {
    recognized = recognizedDefault;
  }
  for (const parameter of protectedHeader.crit) {
    if (!recognized.has(parameter)) {
      throw new JOSENotSupported(`Extension Header Parameter "${parameter}" is not recognized`);
    }
    if (joseHeader[parameter] === undefined) {
      throw new Err(`Extension Header Parameter "${parameter}" is missing`);
    } else if (recognized.get(parameter) && protectedHeader[parameter] === undefined) {
      throw new Err(`Extension Header Parameter "${parameter}" MUST be integrity protected`);
    }
  }
  return new Set(protectedHeader.crit);
}
var validate_crit_default = validateCrit;

// ../../../node_modules/jose/dist/browser/lib/validate_algorithms.js
var validateAlgorithms = (option, algorithms) => {
  if (algorithms !== undefined && (!Array.isArray(algorithms) || algorithms.some((s) => typeof s !== "string"))) {
    throw new TypeError(`"${option}" option must be an array of strings`);
  }
  if (!algorithms) {
    return;
  }
  return new Set(algorithms);
};
var validate_algorithms_default = validateAlgorithms;

// ../../../node_modules/jose/dist/browser/runtime/subtle_dsa.js
function subtleDsa(alg, algorithm) {
  const hash = `SHA-${alg.slice(-3)}`;
  switch (alg) {
    case "HS256":
    case "HS384":
    case "HS512":
      return { hash, name: "HMAC" };
    case "PS256":
    case "PS384":
    case "PS512":
      return { hash, name: "RSA-PSS", saltLength: alg.slice(-3) >> 3 };
    case "RS256":
    case "RS384":
    case "RS512":
      return { hash, name: "RSASSA-PKCS1-v1_5" };
    case "ES256":
    case "ES384":
    case "ES512":
      return { hash, name: "ECDSA", namedCurve: algorithm.namedCurve };
    case "EdDSA":
      return { name: algorithm.name };
    default:
      throw new JOSENotSupported(`alg ${alg} is not supported either by JOSE or your javascript runtime`);
  }
}

// ../../../node_modules/jose/dist/browser/runtime/get_sign_verify_key.js
function getCryptoKey(alg, key, usage) {
  if (isCryptoKey(key)) {
    checkSigCryptoKey(key, alg, usage);
    return key;
  }
  if (key instanceof Uint8Array) {
    if (!alg.startsWith("HS")) {
      throw new TypeError(invalid_key_input_default(key, ...types));
    }
    return webcrypto_default.subtle.importKey("raw", key, { hash: `SHA-${alg.slice(-3)}`, name: "HMAC" }, false, [usage]);
  }
  throw new TypeError(invalid_key_input_default(key, ...types, "Uint8Array"));
}

// ../../../node_modules/jose/dist/browser/runtime/verify.js
var verify = async (alg, key, signature, data) => {
  const cryptoKey = await getCryptoKey(alg, key, "verify");
  check_key_length_default(alg, cryptoKey);
  const algorithm = subtleDsa(alg, cryptoKey.algorithm);
  try {
    return await webcrypto_default.subtle.verify(algorithm, cryptoKey, signature, data);
  } catch (_a) {
    return false;
  }
};
var verify_default = verify;

// ../../../node_modules/jose/dist/browser/jws/flattened/verify.js
async function flattenedVerify(jws, key, options) {
  var _a;
  if (!isObject(jws)) {
    throw new JWSInvalid("Flattened JWS must be an object");
  }
  if (jws.protected === undefined && jws.header === undefined) {
    throw new JWSInvalid('Flattened JWS must have either of the "protected" or "header" members');
  }
  if (jws.protected !== undefined && typeof jws.protected !== "string") {
    throw new JWSInvalid("JWS Protected Header incorrect type");
  }
  if (jws.payload === undefined) {
    throw new JWSInvalid("JWS Payload missing");
  }
  if (typeof jws.signature !== "string") {
    throw new JWSInvalid("JWS Signature missing or incorrect type");
  }
  if (jws.header !== undefined && !isObject(jws.header)) {
    throw new JWSInvalid("JWS Unprotected Header incorrect type");
  }
  let parsedProt = {};
  if (jws.protected) {
    try {
      const protectedHeader = decode(jws.protected);
      parsedProt = JSON.parse(decoder.decode(protectedHeader));
    } catch (_b) {
      throw new JWSInvalid("JWS Protected Header is invalid");
    }
  }
  if (!is_disjoint_default(parsedProt, jws.header)) {
    throw new JWSInvalid("JWS Protected and JWS Unprotected Header Parameter names must be disjoint");
  }
  const joseHeader = {
    ...parsedProt,
    ...jws.header
  };
  const extensions = validate_crit_default(JWSInvalid, new Map([["b64", true]]), options === null || options === undefined ? undefined : options.crit, parsedProt, joseHeader);
  let b64 = true;
  if (extensions.has("b64")) {
    b64 = parsedProt.b64;
    if (typeof b64 !== "boolean") {
      throw new JWSInvalid('The "b64" (base64url-encode payload) Header Parameter must be a boolean');
    }
  }
  const { alg } = joseHeader;
  if (typeof alg !== "string" || !alg) {
    throw new JWSInvalid('JWS "alg" (Algorithm) Header Parameter missing or invalid');
  }
  const algorithms = options && validate_algorithms_default("algorithms", options.algorithms);
  if (algorithms && !algorithms.has(alg)) {
    throw new JOSEAlgNotAllowed('"alg" (Algorithm) Header Parameter not allowed');
  }
  if (b64) {
    if (typeof jws.payload !== "string") {
      throw new JWSInvalid("JWS Payload must be a string");
    }
  } else if (typeof jws.payload !== "string" && !(jws.payload instanceof Uint8Array)) {
    throw new JWSInvalid("JWS Payload must be a string or an Uint8Array instance");
  }
  let resolvedKey = false;
  if (typeof key === "function") {
    key = await key(parsedProt, jws);
    resolvedKey = true;
  }
  check_key_type_default(alg, key, "verify");
  const data = concat(encoder.encode((_a = jws.protected) !== null && _a !== undefined ? _a : ""), encoder.encode("."), typeof jws.payload === "string" ? encoder.encode(jws.payload) : jws.payload);
  let signature;
  try {
    signature = decode(jws.signature);
  } catch (_c) {
    throw new JWSInvalid("Failed to base64url decode the signature");
  }
  const verified = await verify_default(alg, key, signature, data);
  if (!verified) {
    throw new JWSSignatureVerificationFailed;
  }
  let payload;
  if (b64) {
    try {
      payload = decode(jws.payload);
    } catch (_d) {
      throw new JWSInvalid("Failed to base64url decode the payload");
    }
  } else if (typeof jws.payload === "string") {
    payload = encoder.encode(jws.payload);
  } else {
    payload = jws.payload;
  }
  const result = { payload };
  if (jws.protected !== undefined) {
    result.protectedHeader = parsedProt;
  }
  if (jws.header !== undefined) {
    result.unprotectedHeader = jws.header;
  }
  if (resolvedKey) {
    return { ...result, key };
  }
  return result;
}

// ../../../node_modules/jose/dist/browser/jws/compact/verify.js
async function compactVerify(jws, key, options) {
  if (jws instanceof Uint8Array) {
    jws = decoder.decode(jws);
  }
  if (typeof jws !== "string") {
    throw new JWSInvalid("Compact JWS must be a string or Uint8Array");
  }
  const { 0: protectedHeader, 1: payload, 2: signature, length } = jws.split(".");
  if (length !== 3) {
    throw new JWSInvalid("Invalid Compact JWS");
  }
  const verified = await flattenedVerify({ payload, protected: protectedHeader, signature }, key, options);
  const result = { payload: verified.payload, protectedHeader: verified.protectedHeader };
  if (typeof key === "function") {
    return { ...result, key: verified.key };
  }
  return result;
}

// ../../../node_modules/jose/dist/browser/lib/epoch.js
var epoch_default = (date) => Math.floor(date.getTime() / 1000);

// ../../../node_modules/jose/dist/browser/lib/secs.js
var minute = 60;
var hour = minute * 60;
var day = hour * 24;
var week = day * 7;
var year = day * 365.25;
var REGEX = /^(\d+|\d+\.\d+) ?(seconds?|secs?|s|minutes?|mins?|m|hours?|hrs?|h|days?|d|weeks?|w|years?|yrs?|y)$/i;
var secs_default = (str) => {
  const matched = REGEX.exec(str);
  if (!matched) {
    throw new TypeError("Invalid time period format");
  }
  const value = parseFloat(matched[1]);
  const unit = matched[2].toLowerCase();
  switch (unit) {
    case "sec":
    case "secs":
    case "second":
    case "seconds":
    case "s":
      return Math.round(value);
    case "minute":
    case "minutes":
    case "min":
    case "mins":
    case "m":
      return Math.round(value * minute);
    case "hour":
    case "hours":
    case "hr":
    case "hrs":
    case "h":
      return Math.round(value * hour);
    case "day":
    case "days":
    case "d":
      return Math.round(value * day);
    case "week":
    case "weeks":
    case "w":
      return Math.round(value * week);
    default:
      return Math.round(value * year);
  }
};

// ../../../node_modules/jose/dist/browser/lib/jwt_claims_set.js
var normalizeTyp = (value) => value.toLowerCase().replace(/^application\//, "");
var checkAudiencePresence = (audPayload, audOption) => {
  if (typeof audPayload === "string") {
    return audOption.includes(audPayload);
  }
  if (Array.isArray(audPayload)) {
    return audOption.some(Set.prototype.has.bind(new Set(audPayload)));
  }
  return false;
};
var jwt_claims_set_default = (protectedHeader, encodedPayload, options = {}) => {
  const { typ } = options;
  if (typ && (typeof protectedHeader.typ !== "string" || normalizeTyp(protectedHeader.typ) !== normalizeTyp(typ))) {
    throw new JWTClaimValidationFailed('unexpected "typ" JWT header value', "typ", "check_failed");
  }
  let payload;
  try {
    payload = JSON.parse(decoder.decode(encodedPayload));
  } catch (_a) {
  }
  if (!isObject(payload)) {
    throw new JWTInvalid("JWT Claims Set must be a top-level JSON object");
  }
  const { requiredClaims = [], issuer, subject, audience, maxTokenAge } = options;
  if (maxTokenAge !== undefined)
    requiredClaims.push("iat");
  if (audience !== undefined)
    requiredClaims.push("aud");
  if (subject !== undefined)
    requiredClaims.push("sub");
  if (issuer !== undefined)
    requiredClaims.push("iss");
  for (const claim of new Set(requiredClaims.reverse())) {
    if (!(claim in payload)) {
      throw new JWTClaimValidationFailed(`missing required "${claim}" claim`, claim, "missing");
    }
  }
  if (issuer && !(Array.isArray(issuer) ? issuer : [issuer]).includes(payload.iss)) {
    throw new JWTClaimValidationFailed('unexpected "iss" claim value', "iss", "check_failed");
  }
  if (subject && payload.sub !== subject) {
    throw new JWTClaimValidationFailed('unexpected "sub" claim value', "sub", "check_failed");
  }
  if (audience && !checkAudiencePresence(payload.aud, typeof audience === "string" ? [audience] : audience)) {
    throw new JWTClaimValidationFailed('unexpected "aud" claim value', "aud", "check_failed");
  }
  let tolerance;
  switch (typeof options.clockTolerance) {
    case "string":
      tolerance = secs_default(options.clockTolerance);
      break;
    case "number":
      tolerance = options.clockTolerance;
      break;
    case "undefined":
      tolerance = 0;
      break;
    default:
      throw new TypeError("Invalid clockTolerance option type");
  }
  const { currentDate } = options;
  const now = epoch_default(currentDate || new Date);
  if ((payload.iat !== undefined || maxTokenAge) && typeof payload.iat !== "number") {
    throw new JWTClaimValidationFailed('"iat" claim must be a number', "iat", "invalid");
  }
  if (payload.nbf !== undefined) {
    if (typeof payload.nbf !== "number") {
      throw new JWTClaimValidationFailed('"nbf" claim must be a number', "nbf", "invalid");
    }
    if (payload.nbf > now + tolerance) {
      throw new JWTClaimValidationFailed('"nbf" claim timestamp check failed', "nbf", "check_failed");
    }
  }
  if (payload.exp !== undefined) {
    if (typeof payload.exp !== "number") {
      throw new JWTClaimValidationFailed('"exp" claim must be a number', "exp", "invalid");
    }
    if (payload.exp <= now - tolerance) {
      throw new JWTExpired('"exp" claim timestamp check failed', "exp", "check_failed");
    }
  }
  if (maxTokenAge) {
    const age = now - payload.iat;
    const max = typeof maxTokenAge === "number" ? maxTokenAge : secs_default(maxTokenAge);
    if (age - tolerance > max) {
      throw new JWTExpired('"iat" claim timestamp check failed (too far in the past)', "iat", "check_failed");
    }
    if (age < 0 - tolerance) {
      throw new JWTClaimValidationFailed('"iat" claim timestamp check failed (it should be in the past)', "iat", "check_failed");
    }
  }
  return payload;
};

// ../../../node_modules/jose/dist/browser/jwt/verify.js
async function jwtVerify(jwt, key, options) {
  var _a;
  const verified = await compactVerify(jwt, key, options);
  if (((_a = verified.protectedHeader.crit) === null || _a === undefined ? undefined : _a.includes("b64")) && verified.protectedHeader.b64 === false) {
    throw new JWTInvalid("JWTs MUST NOT use unencoded payload");
  }
  const payload = jwt_claims_set_default(verified.protectedHeader, verified.payload, options);
  const result = { payload, protectedHeader: verified.protectedHeader };
  if (typeof key === "function") {
    return { ...result, key: verified.key };
  }
  return result;
}
// ../../../node_modules/jose/dist/browser/util/base64url.js
var decode2 = decode;

// ../../../node_modules/jose/dist/browser/util/decode_protected_header.js
function decodeProtectedHeader(token) {
  let protectedB64u;
  if (typeof token === "string") {
    const parts = token.split(".");
    if (parts.length === 3 || parts.length === 5) {
      [protectedB64u] = parts;
    }
  } else if (typeof token === "object" && token) {
    if ("protected" in token) {
      protectedB64u = token.protected;
    } else {
      throw new TypeError("Token does not contain a Protected Header");
    }
  }
  try {
    if (typeof protectedB64u !== "string" || !protectedB64u) {
      throw new Error;
    }
    const result = JSON.parse(decoder.decode(decode2(protectedB64u)));
    if (!isObject(result)) {
      throw new Error;
    }
    return result;
  } catch (_a) {
    throw new TypeError("Invalid Token or Protected Header formatting");
  }
}
// ../../../node_modules/jose/dist/browser/util/decode_jwt.js
function decodeJwt(jwt) {
  if (typeof jwt !== "string")
    throw new JWTInvalid("JWTs must use Compact JWS serialization, JWT must be a string");
  const { 1: payload, length } = jwt.split(".");
  if (length === 5)
    throw new JWTInvalid("Only JWTs using Compact JWS serialization can be decoded");
  if (length !== 3)
    throw new JWTInvalid("Invalid JWT");
  if (!payload)
    throw new JWTInvalid("JWTs must contain a payload");
  let decoded;
  try {
    decoded = decode2(payload);
  } catch (_a) {
    throw new JWTInvalid("Failed to base64url decode the payload");
  }
  let result;
  try {
    result = JSON.parse(decoder.decode(decoded));
  } catch (_b) {
    throw new JWTInvalid("Failed to parse the decoded payload as JSON");
  }
  if (!isObject(result))
    throw new JWTInvalid("Invalid JWT Claims Set");
  return result;
}
// src/client/hooks/use-auth-tokens.ts
import { useRef, useSyncExternalStore } from "react";
var CSRF_STATE_KEY = "secureState";
var TOKEN_LOCALSTORAGE_KEY = "tokens";
var clearStoredTokens = () => {
  window.localStorage.removeItem(CSRF_STATE_KEY);
  window.localStorage.removeItem(TOKEN_LOCALSTORAGE_KEY);
  window.dispatchEvent(new StorageEvent("storage", {
    key: TOKEN_LOCALSTORAGE_KEY,
    newValue: null
  }));
};
var updateStoredTokens = (payload) => {
  try {
    const newToken = decodeJwt(payload.accessToken);
    const newValue = {
      accessToken: {
        ...newToken,
        raw: payload.accessToken
      },
      refreshToken: payload.refreshToken,
      exp: newToken.exp * 1000
    };
    window.localStorage.removeItem(CSRF_STATE_KEY);
    window.localStorage.setItem(TOKEN_LOCALSTORAGE_KEY, JSON.stringify(newValue));
    window.dispatchEvent(new StorageEvent("storage", {
      key: TOKEN_LOCALSTORAGE_KEY,
      newValue: JSON.stringify(newValue)
    }));
  } catch (error) {
    console.error(error, JSON.stringify(payload));
  }
};
var useAuthTokens = () => {
  const latestKey = useRef(null);
  const latestValue = useRef(null);
  const localStore = {
    getSnapshot: () => {
      const storedData = localStorage.getItem(TOKEN_LOCALSTORAGE_KEY);
      if (!storedData) {
        latestKey.current = null;
        latestValue.current = null;
        return null;
      }
      try {
        if (storedData === latestKey.current && latestValue.current !== null) {
          return latestValue.current;
        }
        latestKey.current = storedData;
        latestValue.current = JSON.parse(storedData);
        return latestValue.current;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    subscribe: (listener) => {
      window.addEventListener("storage", listener);
      return () => void window.removeEventListener("storage", listener);
    }
  };
  return useSyncExternalStore(localStore.subscribe, localStore.getSnapshot);
};

// src/client/util/pkce.ts
var generateCodeVerifier = (len = 64) => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let codeVerifier = "";
  for (let i = 0;i < len; i++) {
    codeVerifier += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return codeVerifier;
};
var generateChallenge = async (codeVerifier) => {
  const encoder2 = new TextEncoder;
  const data = encoder2.encode(codeVerifier);
  const digest = await crypto.subtle.digest("SHA-256", data);
  const uint8Array = Array.from(new Uint8Array(digest));
  const base64String = window.btoa(String.fromCharCode.apply(null, uint8Array));
  return base64String.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
};
var generatePkceConfig = async () => {
  const codeVerifier = generateCodeVerifier(64);
  const challenge = await generateChallenge(codeVerifier);
  return { challenge, codeVerifier };
};

// src/client/util/tokens.ts
var FIVE_MINUTES = 5 * 60 * 1000;
var SIXTY_SECONDS = 60 * 1000;
var THIRTY_SECONDS = 30 * 1000;
var fetchAuthToken = async (code, clientId, tokenUrl, codeVerifier, redirectUri) => {
  const formData = new URLSearchParams;
  formData.append("client_id", clientId);
  formData.append("code", code);
  formData.append("grant_type", "authorization_code");
  formData.append("redirect_uri", redirectUri);
  formData.append("code_verifier", codeVerifier);
  return fetch(tokenUrl, {
    method: "POST",
    headers: {
      "content-type": "application/x-www-form-urlencoded"
    },
    body: formData.toString()
  });
};
var fetchRefreshToken = async (code, clientId, tokenUrl) => {
  const formData = new URLSearchParams;
  formData.append("client_id", clientId);
  formData.append("grant_type", "refresh_token");
  formData.append("refresh_token", code);
  return fetch(tokenUrl, {
    method: "POST",
    headers: {
      "content-type": "application/x-www-form-urlencoded"
    },
    body: formData.toString()
  });
};
var performRefresh = async (refreshToken, clientId, tokenUrl) => {
  const response = await fetchRefreshToken(refreshToken, clientId, tokenUrl);
  if (!response.ok) {
    throw new Error;
  }
  const jwt = await response.json();
  if ("error" in jwt) {
    console.warn(jwt.error);
    jwt.error_description && console.warn(jwt.error_description);
    let err = jwt.error;
    if (jwt.error_description) {
      err += ` -- ${jwt.error_description}`;
    }
    throw new Error(err);
  }
  if (jwt.access_token && jwt.refresh_token) {
    updateStoredTokens({
      accessToken: jwt.access_token,
      refreshToken: jwt.refresh_token
    });
  } else {
    throw new Error("access token and/or refresh token missing from response");
  }
};
var getOrRefreshToken = async (clientId, tokenUrl) => {
  const storedData = localStorage.getItem(TOKEN_LOCALSTORAGE_KEY);
  if (!storedData || !clientId || !tokenUrl) {
    return null;
  }
  try {
    const tokens = JSON.parse(storedData);
    const expiry = tokens.exp;
    if (!tokens.accessToken) {
      throw new Error("this should not be possible");
    }
    if (expiry > Date.now() + SIXTY_SECONDS) {
      return tokens.accessToken.raw;
    }
    await performRefresh(tokens.refreshToken, clientId, tokenUrl);
    return getOrRefreshToken(clientId, tokenUrl);
  } catch {
    clearStoredTokens();
  }
  return null;
};

// src/client/util/login.ts
var AUTH_FLOW = {
  Deeplink: 1,
  Employer: 2,
  CreateAccount: 3,
  SignIn: 4,
  UpdateProfile: 5
};
var initiateLoginChallenge = async ({
  mustReauthenticate = false,
  authUrl,
  clientId,
  redirectUri,
  returnUrl,
  state
}) => {
  const { challenge, codeVerifier } = await generatePkceConfig();
  const authState = {
    ...state,
    codeVerifier,
    currentUrl: returnUrl,
    mustReauthenticate,
    stateKey: CSRF_STATE_KEY
  };
  const payload = window.btoa(JSON.stringify(authState));
  localStorage.setItem(CSRF_STATE_KEY, payload);
  const newUrl = new URL(authUrl);
  newUrl.searchParams.set("client_id", clientId);
  newUrl.searchParams.set("redirect_uri", redirectUri);
  newUrl.searchParams.set("response_type", "code");
  newUrl.searchParams.set("state", payload);
  newUrl.searchParams.set("code_challenge_method", "S256");
  newUrl.searchParams.set("code_challenge", challenge);
  window.location.replace(decodeURIComponent(newUrl.toString()));
};
var validateIncomingState = (state) => {
  try {
    const decodedState = JSON.parse(window.atob(state));
    const localState = localStorage.getItem(decodedState.stateKey);
    if (!localState) {
      return null;
    }
    const decodedLocalState = JSON.parse(window.atob(localState));
    if (decodedState.currentUrl === decodedLocalState.currentUrl && decodedState.codeVerifier === decodedLocalState.codeVerifier) {
      return decodedLocalState;
    }
  } catch {
  }
  return null;
};
var processLoginResponse = async ({
  clientId,
  code,
  redirectUri,
  state = "",
  tokenUrl
}) => {
  if (!code) {
    return { error: "response code is missing to get the tokens" };
  }
  const authState = validateIncomingState(state);
  if (!authState) {
    return {
      error: "State does not match while getting code. Possible CSRF attack"
    };
  }
  const response = await fetchAuthToken(code, clientId, tokenUrl, authState.codeVerifier, redirectUri);
  const jwt = await response.json();
  if ("error" in jwt) {
    return {
      error: jwt.error,
      errorDescription: jwt.error_description
    };
  }
  if (jwt.access_token && jwt.refresh_token) {
    updateStoredTokens({
      accessToken: jwt.access_token,
      refreshToken: jwt.refresh_token
    });
    return { nextUrl: authState.currentUrl };
  }
  return { error: "access or refresh token is missing" };
};

// src/client/components/AuthHeartbeat.tsx
import { useEffect, useState } from "react";

// /home/vsts/work/1/s/node_modules/@phx/browser-utils/dist/index.js
var r = (t) => {
  const e = localStorage.getItem(t);
  if (!e)
    return;
  try {
    const o = JSON.parse(e);
    if (o === null) {
      localStorage.removeItem(t);
      return;
    }
    return o;
  } catch {
    localStorage.removeItem(t);
  }
};
var m = (t, e) => {
  if (e === undefined || e === null) {
    localStorage.removeItem(t);
    return;
  }
  if (typeof e === "object") {
    localStorage.setItem(t, JSON.stringify(e));
    return;
  }
  localStorage.setItem(t, String(e));
};

// src/client/hooks/use-token-refresh-error.ts
import { useSyncExternalStore as useSyncExternalStore2 } from "react";
var REFRESH_ERROR_KEY = "refresh_error";
var clearRefreshError = () => {
  m(REFRESH_ERROR_KEY, null);
  window.dispatchEvent(new StorageEvent("storage", {
    key: REFRESH_ERROR_KEY,
    newValue: null
  }));
};
var setRefreshError = () => {
  m(REFRESH_ERROR_KEY, "true");
  window.dispatchEvent(new StorageEvent("storage", {
    key: REFRESH_ERROR_KEY,
    newValue: "true"
  }));
};
var useTokenRefreshError = () => {
  const localStore = {
    getSnapshot: () => {
      const storedData = r(REFRESH_ERROR_KEY);
      return !!storedData;
    },
    subscribe: (listener) => {
      window.addEventListener("storage", listener);
      return () => void window.removeEventListener("storage", listener);
    }
  };
  return useSyncExternalStore2(localStore.subscribe, localStore.getSnapshot);
};

// src/client/components/AuthHeartbeat.tsx
var AuthHeartbeat = ({
  clientId,
  expiry,
  logoutUrl,
  refreshToken,
  tokenUrl
}) => {
  const [nextCheck, setNextCheck] = useState(-1);
  useEffect(() => {
    if (expiry > Date.now() + SIXTY_SECONDS) {
      setTimeout(() => {
        setNextCheck(Date.now() + THIRTY_SECONDS);
      }, THIRTY_SECONDS);
      return;
    }
    try {
      const doRefresh = async () => {
        const response = await fetchRefreshToken(refreshToken, clientId, tokenUrl);
        if (!response.ok) {
          throw new Error;
        }
        const jwt = await response.json();
        if ("error" in jwt) {
          console.warn(jwt.error);
          jwt.error_description && console.warn(jwt.error_description);
          let err = jwt.error;
          if (jwt.error_description) {
            err += ` -- ${jwt.error_description}`;
          }
          throw new Error(err);
        }
        if (jwt.access_token && jwt.refresh_token) {
          updateStoredTokens({
            accessToken: jwt.access_token,
            refreshToken: jwt.refresh_token
          });
        } else {
          throw new Error("access token and/or refresh token missing from response");
        }
      };
      doRefresh();
    } catch {
      setRefreshError();
      window.location.href = logoutUrl;
    }
  }, [clientId, expiry, nextCheck, refreshToken, tokenUrl]);
  return null;
};

// src/client/components/AuthRefreshError.tsx
import { Body1, Button, Modal, Stack } from "@phx/design-system";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

// src/client/hooks/use-auth-context.ts
import { useContext } from "react";
var useAuthContext = () => {
  const ctx = useContext(AuthContext);
  if (ctx === null) {
    throw new Error("useAuthContext() cannot be called outside of an AuthProvider");
  }
  return ctx;
};

// src/client/components/AuthRefreshError.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var AuthRefreshError = () => {
  const { t } = useTranslation("auth");
  const { signIn } = useAuthContext();
  const hasRefreshError = useTokenRefreshError();
  const clearAndSignIn = useCallback(() => {
    clearRefreshError();
    signIn();
  }, [signIn]);
  if (!hasRefreshError) {
    return null;
  }
  return /* @__PURE__ */ jsx(Modal, {
    opened: true,
    onClose: clearRefreshError,
    title: t("errors.tokenRefresh.heading"),
    children: /* @__PURE__ */ jsxs(Stack, {
      gap: "sm",
      children: [
        /* @__PURE__ */ jsx(Body1, {
          children: t("errors.tokenRefresh.message")
        }),
        /* @__PURE__ */ jsx(Stack, {
          gap: "xs",
          children: /* @__PURE__ */ jsx(Button, {
            fullWidth: true,
            onClick: clearAndSignIn,
            children: t("errors.tokenRefresh.cta")
          })
        })
      ]
    })
  });
};

// src/client/components/AuthProvider.tsx
import { jsx as jsx2, jsxs as jsxs2 } from "react/jsx-runtime";
var AuthContext = createContext(null);
var SIGNOUT_DELAY = 500;
var AuthProvider = ({
  children,
  authUrl,
  autoLogout = FIVE_MINUTES,
  clientId,
  logoutUrl = "/logout",
  redirectUri,
  tokenUrl,
  rootUrl
}) => {
  const { t } = useTranslation2("auth");
  const tokens = useAuthTokens();
  const isIdle = useIdle(autoLogout, {
    initialState: false,
    events: ["click", "keypress"]
  });
  const showIdleTimeout = useIdle(autoLogout - SIXTY_SECONDS, {
    initialState: false,
    events: ["click", "keypress"]
  });
  const expiry = tokens?.exp;
  const refreshToken = tokens?.refreshToken;
  const isAuthenticated = Boolean(tokens?.accessToken);
  const signIn = useCallback2((returnUrl = "", state = {}) => {
    initiateLoginChallenge({
      authUrl,
      clientId,
      redirectUri,
      returnUrl: returnUrl === "" ? redirectUri : returnUrl,
      state
    });
  }, [authUrl, clientId, redirectUri]);
  const signUp = useCallback2((returnUrl = "", state = {}) => {
    signIn(returnUrl, {
      ...state,
      flow: AUTH_FLOW.CreateAccount
    });
  }, [signIn]);
  const signOut = useCallback2((returnUrl) => {
    clearStoredTokens();
    const timeout = setTimeout(() => {
      if (returnUrl) {
        window.location.href = returnUrl;
      } else {
        window.location.href = rootUrl;
      }
    }, SIGNOUT_DELAY);
    return () => {
      clearTimeout(timeout);
    };
  }, [rootUrl]);
  useEffect2(() => {
    if (isIdle && isAuthenticated) {
      window.location.href = logoutUrl;
    }
  }, [isAuthenticated, isIdle, signOut]);
  const ctx = {
    authUrl,
    clientId,
    isAuthenticated,
    redirectUri,
    rootUrl,
    signIn,
    signOut,
    signUp,
    tokens,
    tokenUrl,
    user: tokens?.accessToken ? { id: tokens.accessToken.sub, token: tokens?.accessToken } : null
  };
  const pollForRefresh = expiry && refreshToken;
  return /* @__PURE__ */ jsxs2(AuthContext.Provider, {
    value: ctx,
    children: [
      children,
      /* @__PURE__ */ jsx2(AuthRefreshError, {}),
      pollForRefresh && /* @__PURE__ */ jsx2(AuthHeartbeat, {
        clientId,
        expiry,
        logoutUrl,
        refreshToken,
        tokenUrl
      }),
      isAuthenticated && showIdleTimeout ? /* @__PURE__ */ jsx2(Modal2, {
        opened: true,
        onClose: () => {
        },
        title: t("idle.timeout.heading"),
        children: /* @__PURE__ */ jsxs2(Stack2, {
          gap: "sm",
          children: [
            /* @__PURE__ */ jsx2(Body12, {
              children: t("idle.timeout.message")
            }),
            /* @__PURE__ */ jsx2(Stack2, {
              gap: "xs",
              children: /* @__PURE__ */ jsx2(Button2, {
                fullWidth: true,
                children: t("idle.timeout.cta")
              })
            })
          ]
        })
      }) : null
    ]
  });
};
// src/client/components/AuthorizedOutlet.tsx
import { Outlet, useLocation } from "react-router-dom";
import { jsx as jsx3 } from "react/jsx-runtime";
var AuthorizedRoute = ({ children }) => {
  const { isAuthenticated, signIn } = useAuthContext();
  const { pathname, search } = useLocation();
  if (!isAuthenticated) {
    signIn(`${pathname}${search}`);
    return null;
  }
  return children;
};
var AuthorizedOutlet = () => /* @__PURE__ */ jsx3(AuthorizedRoute, {
  children: /* @__PURE__ */ jsx3(Outlet, {})
});
// src/client/components/RequireAuth.tsx
var RequireAuth = ({
  children,
  fallback = null
}) => {
  const { isAuthenticated } = useAuthContext();
  if (!isAuthenticated) {
    return fallback;
  }
  return children;
};
// src/client/components/routes/LoginCallback.tsx
import { Overlay } from "@phx/design-system";
import { useEffect as useEffect3, useRef as useRef2 } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { jsx as jsx4 } from "react/jsx-runtime";
var LoginCallback = () => {
  const { clientId, redirectUri, rootUrl, tokens, tokenUrl } = useAuthContext();
  const accessToken = tokens?.accessToken ?? null;
  const isLoggingIn = useRef2(false);
  const navigate = useNavigate();
  const [params] = useSearchParams();
  useEffect3(() => {
    if (accessToken || isLoggingIn.current) {
      return;
    }
    isLoggingIn.current = true;
    try {
      const code = params.get("code");
      const state = params.get("state");
      const loginError = params.get("error");
      if (!code || !state || loginError) {
        throw new Error(loginError ?? "Unknown login error");
      }
      const runCallback = async () => {
        const response = await processLoginResponse({
          clientId,
          code,
          redirectUri,
          state,
          tokenUrl
        });
        if ("error" in response) {
          console.warn(response.error);
          response.errorDescription && console.warn(response.errorDescription);
          let err = response.error;
          if (response.errorDescription) {
            err += ` -- ${response.errorDescription}`;
          }
          throw new Error(err);
        }
        if (response.nextUrl && response.nextUrl !== "/") {
          navigate(response.nextUrl);
        } else {
          navigate(rootUrl);
        }
      };
      runCallback();
    } catch (error) {
      console.warn(error);
      navigate(rootUrl);
    }
  }, [accessToken, params]);
  return /* @__PURE__ */ jsx4(Overlay, {
    loader: true
  });
};

// src/client/components/routes/LogoutRoute.tsx
import { useLayoutEffect } from "react";
var LogoutRoute = ({ returnUrl }) => {
  const ctx = useAuthContext();
  useLayoutEffect(() => {
    ctx.signOut(returnUrl);
  }, []);
  return null;
};
export {
  useAuthContext,
  getOrRefreshToken,
  RequireAuth,
  LogoutRoute,
  LoginCallback,
  AuthorizedRoute,
  AuthorizedOutlet,
  AuthProvider,
  AUTH_FLOW
};

//# debugId=CC8E50DE42D808AD64756E2164756E21
