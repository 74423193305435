import { type Geolocation, geolocationDistance } from '@phx/location-utils';

import { getFragment, getFragments } from '../../graphql';
import {
    type ChainProviderInfoFragment,
    type MailOrderProviderInfoFragment,
    type OffersConnectionInfoFragment,
    type PharmacyProviderInfoFragment,
    type ProviderOffersInfoFragment,
} from '../../graphql/generated/graphql';

export function getPrimaryChainProvider(
    provider: ChainProviderInfoFragment,
    geolocation?: Geolocation
): PharmacyProviderInfoFragment | undefined {
    if (provider?.pharmacies?.length) {
        if (!geolocation) {
            return getFragment(provider.pharmacies[0]);
        }
        const getDistance = (pharmacy: PharmacyProviderInfoFragment) =>
            geolocationDistance(geolocation, {
                latitude: pharmacy.latitude,
                longitude: pharmacy.longitude,
            });

        let closestPharmacy: PharmacyProviderInfoFragment | undefined;
        let shortestDistance: number | undefined;

        provider.pharmacies.forEach((pharmacy) => {
            const pharmacyFragment = getFragment(pharmacy);
            const distance = getDistance(pharmacyFragment);
            if (shortestDistance === undefined || distance < shortestDistance) {
                closestPharmacy = pharmacyFragment;
                shortestDistance = distance;
            }
        });
        return closestPharmacy;
    }
    return undefined;
}

export function getProvider(
    providerOffers: ProviderOffersInfoFragment,
    id: string
): MailOrderProviderInfoFragment | PharmacyProviderInfoFragment | undefined {
    if (providerOffers.provider.__typename === 'ChainProvider') {
        const match = getFragment(providerOffers.provider).pharmacies?.find(
            (pharmacy) => getFragment(pharmacy).id === id
        );

        if (match) {
            return getFragment(match);
        }
    }

    return getPrimaryProvider(providerOffers);
}

export function getPrimaryProvider(
    providerOffers: ProviderOffersInfoFragment,
    geolocation?: Geolocation
) {
    const provider = getFragment(providerOffers.provider);

    switch (provider.__typename) {
        case 'ChainProvider':
            return getPrimaryChainProvider(provider, geolocation);
        case 'MailOrderProvider':
        case 'PharmacyProvider':
            return provider;
    }

    return undefined;
}

export function getProviderOffersByType(
    offersConnection: OffersConnectionInfoFragment | undefined,
    featuredOfferId: string = ''
) {
    return {
        pickup:
            getFragments(
                offersConnection?.providerOffersByType?.pickup
            )?.filter((entry) => entry.id !== featuredOfferId) ?? [],
        delivery:
            getFragments(
                offersConnection?.providerOffersByType?.delivery
            )?.filter((entry) => entry.id !== featuredOfferId) ?? [],
    };
}
