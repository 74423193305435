import {
    Anchor,
    Body1,
    Body2,
    Flex,
    IconCached,
    IconEvent,
    IconEventBusy,
    IconMedication,
    IconNoteAlt,
    IconNumbers,
    IconStethoscope,
    IconUpdate,
    Stack,
    formatPhoneNumber,
} from '@phx/design-system';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { ConditionalComponent } from '../../../components/utility/ConditionalComponent';
import { getFragment } from '../../../graphql';
import type {
    DigitallyReceivedPrescriberOrderInfoFragment,
    PriceQuoteInfoFragment,
} from '../../../graphql/generated/graphql';
import { useFlags } from '../../../hooks/use-flags';
import { formatDateString } from '../../../util';

import classes from './prescriber-order-details.module.css';

export type PrescriberOrderDetailsProps = {
    prescriberOrder: DigitallyReceivedPrescriberOrderInfoFragment;
    priceQuote?: PriceQuoteInfoFragment;
    showQuantity?: boolean;
    showPrescriber?: boolean;
};

const DataItem = ({
    description,
    icon,
}: {
    description: ReactNode;
    icon: ReactNode;
}) => {
    return (
        <Flex gap="xs">
            {icon}
            {description}
        </Flex>
    );
};

export const PrescriberOrderDetails = ({
    prescriberOrder,
    priceQuote,
    showQuantity = false,
    showPrescriber = true,
}: PrescriberOrderDetailsProps) => {
    const { t, i18n } = useTranslation();
    const { dosageInstructionsEnabled } = useFlags();

    const writtenDate = formatDateString(
        prescriberOrder.writtenDate,
        i18n.language
    );
    const expirationDate = prescriberOrder.expirationDate
        ? formatDateString(prescriberOrder.expirationDate)
        : null;

    const validInstructions =
        prescriberOrder.productInstruction?.filter(Boolean);
    const instructions = validInstructions?.join(' ');

    const prescriber = getFragment(prescriberOrder.prescriber);
    const prescriberPhone = prescriber.phoneNumber;

    return (
        <Stack gap="sm" className={classes['prescriber-order-details']}>
            <img
                src="/static/rx-details/rx-details.svg"
                aria-hidden="true"
                height="150"
                width="135"
            />
            <ConditionalComponent condition={showPrescriber}>
                <DataItem
                    data-testid="prescription-details-prescriber"
                    description={
                        <Stack gap={0}>
                            <Body1>
                                {t('order.prescriber', {
                                    name: prescriber.fullNameWithTitle,
                                })}
                            </Body1>
                            {prescriberPhone ? (
                                <Anchor textDecoration="underline">
                                    {formatPhoneNumber(prescriberPhone)}
                                </Anchor>
                            ) : null}
                        </Stack>
                    }
                    icon={<IconStethoscope color="secondary" />}
                />
            </ConditionalComponent>
            <ConditionalComponent
                condition={Boolean(
                    dosageInstructionsEnabled && validInstructions?.length
                )}
            >
                <DataItem
                    data-testid="prescription-details-directions"
                    description={<Body1>{instructions}</Body1>}
                    icon={<IconNoteAlt color="secondary" />}
                />
            </ConditionalComponent>
            <ConditionalComponent condition={showQuantity}>
                <DataItem
                    data-testid="prescription-details-quantity"
                    description={
                        <Body1>
                            {t('order.prescribedQuantity', {
                                quantity: prescriberOrder.quantity,
                            })}
                        </Body1>
                    }
                    icon={<IconMedication color="secondary" />}
                />
            </ConditionalComponent>
            <ConditionalComponent
                condition={Boolean(prescriberOrder.daysSupply)}
            >
                <DataItem
                    data-testid="prescription-details-day-supply"
                    description={
                        <Body1>
                            {t('drugDetails.daysSupply', {
                                count: prescriberOrder.daysSupply!,
                            })}
                        </Body1>
                    }
                    icon={<IconUpdate color="secondary" />}
                />
            </ConditionalComponent>
            <DataItem
                data-testid="prescription-details-refills"
                description={
                    <Body1>
                        {t('order.refillCount', {
                            count: prescriberOrder.numberOfRefills,
                        })}
                    </Body1>
                }
                icon={<IconCached color="secondary" />}
            />
            <DataItem
                data-testid="prescription-details-written-date"
                description={
                    <Body1>{t('order.writtenOn', { date: writtenDate })}</Body1>
                }
                icon={<IconEvent color="secondary" />}
            />
            <ConditionalComponent condition={expirationDate !== null}>
                <DataItem
                    data-testid="prescription-details-expiration-date"
                    description={
                        <Body1>
                            {t('order.expiresOn', { date: expirationDate })}
                        </Body1>
                    }
                    icon={<IconEventBusy color="secondary" />}
                />
            </ConditionalComponent>
            <ConditionalComponent condition={priceQuote !== undefined}>
                <DataItem
                    data-testid="prescription-details-ndc"
                    description={
                        <Body1>
                            {t('order.ndc', { ndc: priceQuote?.product.id })}
                        </Body1>
                    }
                    icon={<IconNumbers color="secondary" />}
                />
            </ConditionalComponent>
            <Body2>{t('order.officialPrescription')}</Body2>
        </Stack>
    );
};
