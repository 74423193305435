import {
    Body1,
    Button,
    DefinitionList,
    HorizontalDivider,
    Stack,
    Subtitle1,
} from '@phx/design-system';
import { constants } from '@phx/design-system/framework';
import { useIsMobile } from '@phx/design-system/hooks';
import { useGeolocation } from '@phx/location-utils';
import {
    CashOfferPriceButton,
    InsuranceOfferPriceButton,
} from '@phx/myphx-lib';
import { type ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { getFragment } from '../../graphql';
import {
    type CashOfferInfoFragment,
    type InsuranceOfferInfoFragment,
    type ProviderOffersInfoFragment,
} from '../../graphql/generated/graphql';
import {
    isValidOfferPricing,
    isValidPatientCost,
} from '../../graphql/policies/util';
import { formatToLocalizedCurrency } from '../../util';
import { getCheapestPatientCostOffer } from '../../util/offers/get-cheapest-patient-cost-offer';
import { getOffersByProviderId } from '../../util/offers/get-offers-by-provider-id';
import { getPriceableOffers } from '../../util/offers/get-priceable-offers';
import {
    getPrimaryProvider,
    getProvider,
} from '../../util/provider-offers/provider-offers-utils';
import { ShoppingHeader } from '../cabinet/shopping-header/ShoppingHeader';
import { InfoHeading } from '../common/InfoHeading';
import {
    type TranslationBlocks,
    asDefinitionListItem,
} from '../utility/as-definition-list-item';

type OfferSelectionProps = {
    providerOffers: ProviderOffersInfoFragment;
    header: ReactNode;
    buttonText: string;
    onSelect: (selectedOfferId: string) => void;
};

export const OfferSelection = ({
    providerOffers,
    header,
    buttonText,
    onSelect,
}: OfferSelectionProps) => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const { geolocation } = useGeolocation();

    const providerId =
        searchParams.get('providerId') ??
        getPrimaryProvider(providerOffers, geolocation)?.id ??
        getFragment(providerOffers.provider)?.id;

    const offers = getOffersByProviderId(
        getPriceableOffers(providerOffers),
        providerId
    ) as Array<CashOfferInfoFragment | InsuranceOfferInfoFragment>;
    const isMobile = useIsMobile();

    const [selectedOffer, setSelectedOffer] = useState<
        CashOfferInfoFragment | InsuranceOfferInfoFragment | undefined
    >(getCheapestPatientCostOffer(offers));

    const pharmacy = getProvider(providerOffers, providerId);

    const dialogContentBlocks: TranslationBlocks = t(
        'offerSelection.infoDialog.content',
        {
            returnObjects: true,
            tm: constants.unicode.TRADEMARK,
        }
    );

    return (
        <Stack gap="lg" flex="1">
            <Stack gap="lg" flex={isMobile ? '1' : '0'}>
                <ShoppingHeader>{header}</ShoppingHeader>
                <HorizontalDivider variant="full" />
                <Stack gap="sm">
                    <InfoHeading
                        title={t('offerSelection.title')}
                        informationDialog={{
                            title: t('offerSelection.infoDialog.title'),
                            content: (
                                <DefinitionList
                                    compact={false}
                                    items={dialogContentBlocks.map(
                                        asDefinitionListItem
                                    )}
                                />
                            ),
                        }}
                    />
                    <Stack gap="xxs">
                        {pharmacy && <Subtitle1>{pharmacy.name}</Subtitle1>}
                        <Body1>{t('offerSelection.subtitle')}</Body1>
                    </Stack>
                </Stack>
                <Stack gap="sm">
                    {offers.map((offer, i) => {
                        if (isValidOfferPricing(offer)) {
                            const isSelected = offer.id === selectedOffer?.id;
                            const offerType = offer.__typename;

                            const price = isValidPatientCost(offer)
                                ? formatToLocalizedCurrency(
                                      offer.patientResponsibilityCost
                                  )
                                : undefined;

                            if (offerType === 'InsuranceOffer') {
                                const planPays = formatToLocalizedCurrency(
                                    offer.planPaysAmount
                                );
                                return (
                                    <InsuranceOfferPriceButton
                                        key={`offer-${offer.id}-insurance-${i}`}
                                        price={price}
                                        planPays={planPays}
                                        isSelected={isSelected}
                                        onClick={() => setSelectedOffer(offer)}
                                    />
                                );
                            } else if (offerType === 'CashOffer') {
                                return (
                                    <CashOfferPriceButton
                                        key={`offer-${offer.id}-cash-${i}`}
                                        price={price}
                                        isSelected={isSelected}
                                        onClick={() => setSelectedOffer(offer)}
                                    />
                                );
                            }
                        }

                        return null;
                    })}
                </Stack>
            </Stack>
            <Button
                size="lg"
                disabled={!selectedOffer}
                onClick={() => selectedOffer && onSelect(selectedOffer.id)}
            >
                {buttonText}{' '}
                {isValidPatientCost(selectedOffer)
                    ? formatToLocalizedCurrency(
                          selectedOffer?.patientResponsibilityCost ?? 0
                      )
                    : undefined}
            </Button>
        </Stack>
    );
};
